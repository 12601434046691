.container {
  height: 37vh;
  padding: 2.5rem;
  background-size: cover;

  /* A hack to make the body to be above leaflet map. It works :) */
  position: relative;
  z-index: 99999;
}

.heading {
  font-size: 2.5rem;
  font-weight: 400;
  color: #fff;
  text-align: center;
  margin-bottom: 3rem;
}
