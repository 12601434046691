.card {
  text-align: center;
  border-radius: 15px;
  background-color: #fff;
  padding: 2.5rem;

  margin: 0 auto;
  width: 100%;
}

@media all and (min-width: 1200px) {
  .card {
    display: flex;
    width: 80%;
    text-align: left;
    padding: 4rem;
  }
}
