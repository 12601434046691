@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
}

html {
  /* If defautl font-size is 16px = 10px will be setted for the font-size*/
  font-size: 62.5%;
}
