@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap);
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
}

html {
  /* If defautl font-size is 16px = 10px will be setted for the font-size*/
  font-size: 62.5%;
}

.Card_card__10yt9 {
  text-align: center;
  border-radius: 15px;
  background-color: #fff;
  padding: 2.5rem;

  margin: 0 auto;
  width: 100%;
}

@media all and (min-width: 1200px) {
  .Card_card__10yt9 {
    display: flex;
    width: 80%;
    text-align: left;
    padding: 4rem;
  }
}

.CardDetails_card_Header__1zPdY {
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: hsl(0, 0%, 59%);
}

.CardDetails_card_Detail__ooM0S {
  font-size: 1.8rem;
  font-weight: 500;
  color: hsl(0, 0%, 17%);
}

.CardDetails_card_Detail__ooM0S:not(:last-child) {
  margin-bottom: 2.3rem;
}

@media all and (min-width: 1200px) {
  .CardDetails_detail_container__388FQ {
    width: 25%;
    margin: 0 1.2rem;
  }

  .CardDetails_detail_container__388FQ:not(:last-child) {
    border-right: 1px solid hsla(0, 0%, 59%, 0.5);
  }

  .CardDetails_card_Header__1zPdY {
    font-size: 1.3rem;
    letter-spacing: 0.2rem;
  }

  .CardDetails_card_Detail__ooM0S {
    font-size: 2.4rem;
  }
}

.Form_input__33ytJ {
  width: 84%;
  height: 6rem;
  color: hsl(0, 0%, 17%);
  border-radius: 15px 0 0 15px;
  border: none;
  font-size: 1.8rem;
  padding: 2.5rem;
  outline: none;
}

.Form_buttonContainer__iZ6gV {
  width: 100%;
  margin: 0 auto;
}

.Form_button__1bBNF {
  width: 16%;
  height: 6rem;
  background-color: #000;
  border: none;
  border-radius: 0 15px 15px 0;
  margin-bottom: 2rem;
  outline: none;
}

.Form_button__1bBNF:hover {
  background-color: hsl(0, 0%, 17%);
  cursor: pointer;
}

@media all and (min-width: 1200px) {
  .Form_buttonContainer__iZ6gV {
    width: 45%;
    margin-bottom: 2rem;
  }

  .Form_input__33ytJ {
    width: 90%;
  }

  .Form_button__1bBNF {
    width: 10%;
  }
}

.Modal_modalContainer__32y1d {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999;
}

.Modal_modalbg___l81E {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
}

.Modal_errorCard__3__sP {
  width: 80vw;
  max-width: 500px;
  background-color: #fff;
  position: absolute;
  top: 5vh;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 15px;
  padding: 1.5rem 2rem;
}

.Modal_modalClose__1iaT4 {
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  font-size: 3rem;
  border: none;
  background-color: transparent;
}

.Modal_modalClose__1iaT4:hover {
  cursor: pointer;
}

.Modal_errorHeader__1c9Mq {
  font-size: 2.8rem;
  position: relative;
  display: inline-block;
  margin-bottom: 1rem;
}

.Modal_errorDetail__R5_fC {
  font-size: 1.8rem;
  margin-top: 1rem;
}

.Modal_border__be8ne {
  border-color: hsla(0, 0%, 59%, 0.5);
}

.Body_container__27DVa {
  height: 37vh;
  padding: 2.5rem;
  background-size: cover;

  /* A hack to make the body to be above leaflet map. It works :) */
  position: relative;
  z-index: 99999;
}

.Body_heading__6nvp_ {
  font-size: 2.5rem;
  font-weight: 400;
  color: #fff;
  text-align: center;
  margin-bottom: 3rem;
}

.Map_mapContainer__1KAay {
  height: 63vh;
}

