.input {
  width: 84%;
  height: 6rem;
  color: hsl(0, 0%, 17%);
  border-radius: 15px 0 0 15px;
  border: none;
  font-size: 1.8rem;
  padding: 2.5rem;
  outline: none;
}

.buttonContainer {
  width: 100%;
  margin: 0 auto;
}

.button {
  width: 16%;
  height: 6rem;
  background-color: #000;
  border: none;
  border-radius: 0 15px 15px 0;
  margin-bottom: 2rem;
  outline: none;
}

.button:hover {
  background-color: hsl(0, 0%, 17%);
  cursor: pointer;
}

@media all and (min-width: 1200px) {
  .buttonContainer {
    width: 45%;
    margin-bottom: 2rem;
  }

  .input {
    width: 90%;
  }

  .button {
    width: 10%;
  }
}
